import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

import 'bootstrap/dist/css/bootstrap.css';
import "./assets/vendor/icon-line-pro/style.css";
import "./assets/vendor/icon-awesome/css/font-awesome.min.css";
import "./assets/css/unify-components.css";
import "./assets/css/unify.css";
import "./assets/css/custom.css";

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

import React, {Component} from 'react';
import GoogleMapReact from 'google-map-react'
import {CarouselProvider, Slider, Slide, Image} from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import {translations} from './translations';
import {values, orderBy, includes} from 'lodash';
import Picker from './components/picker/Picker';
import moment from 'moment';
import {Spin} from 'antd';
import numeral from 'numeral';
import "numeral/locales";
import CookieConsent from "react-cookie-consent";
numeral.locale('nl-be');


const xs = 500;
const sm = 768;
const md = 992;
const xl = 1200;

class App extends Component {

    constructor(props) {
        super(props);


        this.getVisibleVehiclesCount = this.getVisibleVehiclesCount.bind(this);

        this.state = {
            isFetchingStats: false,
            isFetchingVehicles: false,
            errorFetching: '',
            vehicles: [],
            statistics: {},
            teaserCars: this.getVisibleVehiclesCount(window.innerWidth),
            language: this.getBrowserLocale(),
        };

        window.addEventListener('resize', () => this.setState({teaserCars: this.getVisibleVehiclesCount(window.innerWidth)}), true);

        this.getTranslation = this.getTranslation.bind(this);
    }


    getBrowserLocale = () => {
        let locale = 'en';
        const browserLocale = require('browser-locale')();
        if (browserLocale !== '' && includes(browserLocale, '-')) {
            locale = browserLocale.split('-')[0];
        }
        else if (browserLocale !== '' && browserLocale.length === 2) {
            locale = browserLocale;
        }
        return locale;
    };

    getVisibleVehiclesCount(size) {
        if (window.innerWidth <= xs) {
            return 1;
        }
        else if (window.innerWidth > xs && window.innerWidth < sm) {
            return 2;
        }
        else if (window.innerWidth > sm && window.innerWidth < md) {
            return 3;
        }
        else if (window.innerWidth > md && window.innerWidth < xl) {
            return 4;
        }
        else if (window.innerWidth >= xl) {
            return 4;
        }
    }


    getTranslation(key, emptyIfNotExists = false) {
        let temp = translations[this.state.language][key];

        if (emptyIfNotExists)
            return temp;


        if (temp === '' && !emptyIfNotExists)
            temp = translations['en'][key];


        if (!temp.includes('\n')) {
            return temp;
        }
        else {
            return (
                <span>
                    {  temp.split('\n').map((item, key) => {
                        return <span key={key}>{item}<br/></span>
                    })
                    }
                </span>
            )
        }
    }

    componentDidMount() {
        this.setState({isFetchingVehicles: true, isFetchingStats: true});
        fetch('/sales/api/v1/frontpage-vehicles/')
            .then(response => response.json())
            .then(data => {
                const vehicles = data.map((vehicle) => ({
                    make: vehicle.make,
                    model: vehicle.model,
                    date: moment(vehicle.first_registration).format('DD/MM/YYYY'),
                    km: vehicle.kilometers,
                    url: vehicle.thumbnail_url
                }));
                this.setState({isFetchingVehicles: false, vehicles: vehicles});
            })
            .catch(() => {
                this.setState({errorFetching: 'Error loading data'});
            });


        fetch('/sales/api/v1/frontpage/')
            .then(response => response.json())
            .then(data => {
                if (data[0]) {
                    this.setState({isFetchingStats: false, statistics: data[0]});
                }
                else {
                    this.setState({errorFetching: 'Error loading data'})
                }
            })
            .catch(() => {
                this.setState({errorFetching: 'Error loading data'})
            });
    }

    render() {
        const {isFetchingStats, isFetchingVehicles, errorFetching, vehicles, statistics} = this.state;
        const gdpr_cookie = "vdfin-gdpr-cookie";
        return (
            <main >
                {/* HEADER */}
                <header className="g-bg-white align-items-lg-start justify-content-start g-pb-10"
                        style={{paddingTop: 20, paddingLeft: 15, paddingRight: 15}}>
                    <div className="row justify-content-between align-items-center">
                        <div className="col-8 col-sm-7 col-md-6 col-lg-5 col-xl-4">
                            <img src={require("./assets/img/logo.svg")} alt="logo"
                                 style={{maxWidth: '100%', height: 'auto', width: '100%'}}/>
                        </div>
                        <div className="g-max-width-100 g-ml-20 g-mr-20">

                            <Picker renderTitle={() => (
                                <span>
                                    {this.state.language.toUpperCase()} <i
                                    className="fa fa-angle-down g-ml-5 g-font-size-15"></i>
                                </span>
                            )}>

                                <ul className="list-inline mb-0 g-min-width-150 g-color-text ">
                                    {values(orderBy(translations, 'name')).map((tr, i) =>
                                        <li className="list-inline-item row g-ma-0 g-pa-0 clickable"
                                            onClick={() => this.setState({language: tr.code})}>
                                            > {tr.name} ({tr.code.toUpperCase()})
                                        </li>)}

                                </ul>
                            </Picker>


                        </div>
                    </div>

                </header>

                <section id="banner">
                    <img src={require("./assets/img/banner.jpg")} alt="banner"
                         style={{maxWidth: '100%', height: 'auto', width: '100%'}}/>
                </section>

                {/* HOME */}
                <section id="home" className="g-bg-white g-pb-30 g-pt-30">
                    <div className="container">

                        <div className="ml-lg-auto text-center justify-content-center g-font-size-16">

                            <h2 className="g-line-height-1_3 g-font-size-36 g-mb-10--sm">{this.getTranslation('home_title')}</h2>

                            <p>{this.getTranslation('home_text')}</p>

                            <div className="row g-pa-0 g-ma-0 g-pt-20 justify-content-center">

                                <div className="row g-pa-0 g-ma-0 g-width-150">
                                    <a href={`https://www.vdfinremarketing.eu/login?culture=${this.state.language}?${Date.now().toString()}`}
                                       className="btn btn-md u-btn-primary g-font-weight-700 text-uppercase text-center g-py-10 g-mb-15 g-mx-10 g-mx-0--md g-mr-30--md g-width-150">
                                        <span className="g-font-size-13">
                                         {this.getTranslation('login')}
                                        </span>
                                    </a>
                                </div>

                                <div className="row g-pa-0 g-ma-0 g-width-150">
                                    <a href={`https://www.vdfinremarketing.eu/registration?culture=${this.state.language}?${Date.now().toString()}`}
                                       className="btn btn-md u-btn-primary g-font-weight-700 text-uppercase text-center g-py-10 g-mb-15 g-mx-10 g-mx-0--md g-mr-30--md g-width-150">
            <span className="g-font-size-13">
            {this.getTranslation('register')}
    </span>
                                    </a>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>

                {/* TEASER */}
                {this.state.vehicles.length > 0 &&
                <section id="teaser" className="g-py-40 ">
                    <div className="container text-center g-max-width-870 g-font-size-16 g-mb-25">
                        <div className="text-uppercase g-line-height-1_3 g-mb-20">
                            <h3 className="h3 g-line-height-1_3 mb-0">{this.getTranslation('teaser_title')} </h3>
                        </div>
                        <p className="mb-0">{this.getTranslation('teaser_text')}</p>
                    </div>

                    {(isFetchingVehicles || isFetchingStats) &&
                    <Spin tip="Loading..."/>
                    }

                    {errorFetching &&
                    <div className="text-center">{errorFetching}</div>
                    }

                    {!isFetchingVehicles && !isFetchingStats && errorFetching === '' &&
                    <div className="container ">

                        <div className="row text-center text-uppercase justify-content-center">
                            <div className="col-lg-3 col-sm-3 g-brd-right g-brd-primary g-mb-20">
                                <div className="js-counter g-font-size-30 g-font-weight-300 g-mb-7 g-color-primary">
                                    {numeral(statistics.count).format('0,0')}
                                </div>
                                {/*<h4 className="h5 ">Wagens <br /><span className="g-font-size-8">(vandaag)</span></h4>*/}
                                <h4 className="h5 ">{this.getTranslation('teaser_counter_1')} <br /><span
                                    className="g-font-size-8">({this.getTranslation('today')})</span></h4>
                            </div>

                            <div className="col-lg-3 col-sm-3 g-brd-right g-brd-primary g-mb-20">
                                <div className="js-counter g-font-size-30 g-font-weight-300 g-mb-7 g-color-primary">
                                    {numeral(statistics.kilometers).format('0,0')}
                                </div>
                                {/*<h4 className="h5 ">km <br /><span className="g-font-size-8">(gemiddeld)</span></h4>*/}
                                <h4 className="h5 ">{this.getTranslation('teaser_counter_2')} <br /><span
                                    className="g-font-size-8">({this.getTranslation('average')})</span></h4>
                            </div>

                            <div className="col-lg-3 col-sm-3 g-mb-20">
                                <div className="js-counter g-font-size-30 g-font-weight-300 g-mb-7 g-color-primary">
                                    {/*{(statistics.average_age / 12).toFixed(1)}*/}
                                    {statistics.average_age}
                                </div>
                                {/*<h4 className="h5 ">jaar <br /><span className="g-font-size-8">(gemiddeld)</span>*/}
                                <h4 className="h5 ">{this.getTranslation('teaser_counter_3')} <br /><span
                                    className="g-font-size-8">({this.getTranslation('average')})</span></h4>
                            </div>
                        </div>

                        <div>

                            <CarouselProvider
                                naturalSlideWidth={75}
                                naturalSlideHeight={80}
                                totalSlides={vehicles.length}
                                visibleSlides={this.state.teaserCars}
                                currentSlide={0}
                                hasMasterSpinner={true}
                                isPlaying={true}
                                interval={5000}
                                playDirection="forward">

                                <Slider>
                                    {vehicles.map((car, index) =>
                                        <Slide key={index} index={index + 1}>
                                            <div className="g-pl-5 g-pr-5" style={{minHeight: 180}}>
                                                <figure className="u-block  g-bg-primary mb-0 g-transition-0_2">
                                                    <div className="g-pos-rel">
                                                        <Image src={car.url}/>
                                                    </div>
                                                    <div className="g-pa-20">
                                                        <h4 className="g-color-white text-uppercase g-font-size-16 g-mb-5 text-wrap">
                                                            {`${car.make} - ${car.model}`}
                                                        </h4>
                                                        <em className="g-color-white g-font-style-normal g-font-size-default">
                                                            {`${car.date} - ${car.km} km`}
                                                        </em>
                                                    </div>
                                                </figure>
                                            </div>
                                        </Slide>
                                    )}
                                </Slider>
                            </CarouselProvider>


                        </div>
                    </div>}
                </section>}


                {/* HOW */}
                <section id="How" className="g-py-40 g-bg-white">
                    <div className="container text-center g-max-width-870 g-font-size-16 g-mb-25">
                        <div className="text-uppercase g-line-height-1_3 g-mb-20">
                            <h3 className="h3 g-line-height-1_3 mb-0">{this.getTranslation('how_title')}</h3>
                        </div>

                        <p className="mb-0">{this.getTranslation('how_text')}</p>
                    </div>

                    <div className="container">

                        <div className="row">
                            <div className="col-md-6 col-lg-4 g-mb-30">
                                <div className="text-center g-bg-gray-light-v5 g-py-40 g-px-30 g-min-height-312">
            <span className="u-icon-v1 g-font-size-36 g-color-primary g-mb-30"><i
                className="fa fa-handshake-o"></i></span>
                                    <h4 className="text-uppercase g-font-weight-700 g-font-size-11 g-color-black g-mb-20">
                                        {this.getTranslation('how_1_title')}
                                    </h4>
                                    <p className="mb-0">
                                        {this.getTranslation('how_1_text')}
                                    </p>
                                </div>
                            </div>

                            <div className="col-md-6 col-lg-4 g-mb-30">
                                <div className="text-center g-bg-gray-light-v5 g-py-40 g-px-30 g-min-height-312">
            <span className="u-icon-v1 g-font-size-36 g-color-primary g-mb-30"><i
                className="fa fa-shield"></i></span>
                                    <h4 className="text-uppercase g-font-weight-700 g-font-size-11 g-color-black g-mb-20">
                                        {this.getTranslation('how_2_title')}
                                    </h4>
                                    <p className="mb-0">
                                        {this.getTranslation('how_2_text')}
                                    </p>
                                </div>
                            </div>

                            <div className="col-md-6 col-lg-4 g-mb-30">
                                <div className="text-center g-bg-gray-light-v5 g-py-40 g-px-30 g-min-height-312">
            <span className="u-icon-v1 g-font-size-36 g-color-primary g-mb-30"><i
                className="fa fa-gavel"></i></span>
                                    <h4 className="text-uppercase g-font-weight-700 g-font-size-11 g-color-black g-mb-20">
                                        {this.getTranslation('how_3_title')}
                                    </h4>
                                    <p className="mb-0">
                                        {this.getTranslation('how_3_text')}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* USP */}
                <section id="Why" className="g-py-40 g-theme-bg-gray-light-v1">
                    <div className="container text-center g-max-width-870 g-font-size-16 g-mb-25">
                        <div className="text-uppercase g-line-height-1_3 g-mb-20">
                            <h3 className="h3 g-line-height-1_3 mb-0">{this.getTranslation('usp_title')} </h3>
                        </div>

                        <p className="mb-0">{this.getTranslation('usp_text')}</p>
                    </div>

                    <div className="container g-pb-20 ">

                        <div className="row">
                            <div className="col-sm-6 g-px-30 g-mb-10" style={{minHeight: 113}}>

                                <article className="h-100 g-flex-middle g-brd-left g-brd-3 g-brd-primary g-pa-20">
                                    <div className="g-flex-middle-item">
                                        <h4 className="h6 g-font-weight-600 text-uppercase g-mb-10">{this.getTranslation('usp_1_title')}</h4>
                                        <p className="mb-0">{this.getTranslation('usp_1_text')}</p>
                                    </div>
                                </article>

                            </div>

                            <div className="col-sm-6 g-px-30 g-mb-10" style={{minHeight: 113}}>

                                <article className="h-100 g-flex-middle g-brd-left g-brd-3 g-brd-primary g-pa-20">
                                    <div className="g-flex-middle-item">
                                        <h4 className="h6 g-font-weight-600 text-uppercase g-mb-10">{this.getTranslation('usp_2_title')}</h4>
                                        <p className="mb-0">{this.getTranslation('usp_2_text')}</p>
                                    </div>
                                </article>

                            </div>
                        </div>

                        <div className="row">
                            <div className="col-sm-6 g-px-30 g-mb-10" style={{minHeight: 113}}>

                                <article className="h-100 g-flex-middle g-brd-left g-brd-3 g-brd-primary g-pa-20">
                                    <div className="g-flex-middle-item">
                                        <h4 className="h6 g-font-weight-600 text-uppercase g-mb-10">{this.getTranslation('usp_3_title')}</h4>
                                        <p className="mb-0">{this.getTranslation('usp_3_text')}</p>
                                    </div>
                                </article>

                            </div>

                            <div className="col-sm-6 g-px-30 g-mb-10" style={{minHeight: 113}}>

                                <article className="h-100 g-flex-middle g-brd-left g-brd-3 g-brd-primary g-pa-20">
                                    <div className="g-flex-middle-item">
                                        <h4 className="h6 g-font-weight-600 text-uppercase g-mb-10">{this.getTranslation('usp_4_title')}</h4>
                                        <p className="mb-0">{this.getTranslation('usp_4_text')}</p>
                                    </div>
                                </article>

                            </div>
                        </div>
                    </div>

                    <div className="container text-center g-max-width-870 g-font-size-16 g-mb-25">
                        <div className="text-uppercase g-line-height-1_3 g-mb-20">
                            <h3 className="h3 g-line-height-1_3 mb-0">{this.getTranslation('usp_5_title')}</h3>
                        </div>

                        <p className="mb-0">{this.getTranslation('usp_5_text')}</p>
                    </div>
                </section>


                <section id="map">
                    <div className='google-map' style={{height: 400}}>
                        <GoogleMapReact
                            bootstrapURLKeys={{key: 'AIzaSyA1vkduCPZhlRoujUEY6tLLlP0TlH3Jvlc'}}
                            defaultCenter={ {lat: 50.8923763, lng: 4.5689051} }
                            defaultZoom={ 17 }>
                             <i lat={50.8923763}
                                lng={4.5689051}
                                className="fa fa-map-pin g-font-size-30 g-color-primary"/>
                        </GoogleMapReact>
                    </div>
                </section>

                {/* CONTACT */}
                <section id="contact" className="g-bg-primary g-py-40">
                    <div className="container">

                        <div className="row align-items-center">
                            <div className="col-md-7">
                                <h3 className="h4 g-color-white mb-4">{this.getTranslation('contact_title')}</h3>

                                <div className="media align-items-center mb-4">
                                    <div className="d-flex">
            <span className="u-icon-v1 u-icon-size--sm g-color-white mr-2">
            <i className="icon-hotel-restaurant-235 u-line-icon-pro"></i>
            </span>
                                    </div>
                                    <div className="media-body">
                                        <p className="g-color-white-opacity-0_6 mb-0">Leuvensesteenweg 679, 3071 Kortenberg</p>
                                    </div>
                                </div>

                                <div className="media align-items-center mb-4">
                                    <div className="d-flex">
            <span className="u-icon-v1 u-icon-size--sm g-color-white mr-2">
            <i className="icon-communication-033 u-line-icon-pro"></i>
            </span>
                                    </div>
                                    <div className="media-body">
                                        <p className="g-color-white-opacity-0_6 mb-0"><a className="g-color-white-opacity-0_6" href="tel:+32 2 237 90 90">+32 2 237 90 90</a></p>
                                    </div>
                                </div>

                                <div className="media align-items-center g-mb-50">
                                    <div className="d-flex">
            <span className="u-icon-v1 u-icon-size--sm g-color-white mr-2">
            <i className="icon-communication-062 u-line-icon-pro"></i>
            </span>
                                    </div>
                                    <div className="media-body">
                                        <p className="g-color-white-opacity-0_6 mb-0"> <a className="g-color-white-opacity-0_6" href="mailto:remarketing@vdfin.be">remarketing@vdfin.be</a></p>

                                    </div>
                                </div>

                                {/*<h3 className="h4 g-color-white">{this.getTranslation('mobile_title', true)}</h3>*/}

                                {/*<ul className="list-inline mb-0">*/}
                                {/*<li className="list-inline-item g-mx-2">*/}
                                {/*<a className="u-icon-v1 u-icon-size--sm g-color-white g-bg-white-opacity-0_1 g-bg-primary--hover g-font-size-13 rounded-circle"*/}
                                {/*href={''}>*/}
                                {/*<i className="fa fa-apple"></i>*/}
                                {/*</a>*/}
                                {/*</li>*/}
                                {/*<li className="list-inline-item g-mx-2">*/}
                                {/*<a className="u-icon-v1 u-icon-size--sm g-color-white g-bg-white-opacity-0_1 g-bg-primary--hover g-font-size-13 rounded-circle"*/}
                                {/*href={''}>*/}
                                {/*<i className="fa fa-android"></i>*/}
                                {/*</a>*/}
                                {/*</li>*/}
                                {/*</ul>*/}

                                <div className="g-bg-primary g-color-white g-hidden-md-down">
                                <ul className="list-inline g-pa-0 g-ma-0">
                                    <li className="list-inline-item">{this.getTranslation('copyright')}</li>
                                    <li className="list-inline-item mx-3">
                                        <a href="https://www.vdfinremarketing.eu/terms-and-conditions"
                                           className="u-link-v5 g-color-secondary g-font-weight-500 clickable">
                                            {this.getTranslation('conditions')}
                                        </a>
                                    </li>
                                    <li className="list-inline-item mx-3">
                                        <a href="https://www.vdfinremarketing.eu/privacy"
                                           className="u-link-v5 g-color-secondary g-font-weight-500 clickable">
                                            {this.getTranslation('privacy')}
                                        </a>
                                    </li>
                                </ul>
                                </div>

                            </div>

                            <div className="col-md-5 g-hidden-sm-down pull-right"
                                 style={{display: 'flex', justifyContent: 'flex-end'}}>
                                {/*<img src={require("./assets/img/team.png")} alt="team"*/}
                                     {/*style={{height: '80%', width: '80%'}}/>*/}
                            </div>
                        </div>

                        <div className="g-bg-primary g-color-white g-pt-20 text-center g-hidden-lg-up">
                            <ul className="list-inline">
                                <li className="list-inline-item mx-3">{this.getTranslation('copyright')}</li>
                                <li className="list-inline-item mx-3">
                                    <a href="https://www.vdfinremarketing.eu/terms-and-conditions"
                                       className="u-link-v5 g-color-secondary g-font-weight-500 clickable">
                                        {this.getTranslation('conditions')}
                                    </a>
                                </li>
                                <li className="list-inline-item mx-3">
                                    <a href="https://www.vdfinremarketing.eu/privacy"
                                       className="u-link-v5 g-color-secondary g-font-weight-500 clickable">
                                        {this.getTranslation('privacy')}
                                    </a>
                                </li>
                            </ul>
                        </div>

                    </div>
                </section>

                <CookieConsent
                    debug={false}
                    location="bottom"
                    buttonText={this.getTranslation('GDPR_accept_btn')}
                    cookieName={gdpr_cookie}
                    style={{background: "#2B373B"}}
                    buttonStyle={{backgroundColor: "white", color: 'black', fontSize: "13px", margin: 30}}
                    extraCookieOptions={{domain: 'vdfinusedcars.eu'}}
                    contentStyle={{margin: 30}}
                    expires={150}
                >
                {this.getTranslation("GDPR_line_1")}<br/>
                {this.getTranslation("GDPR_line_2")} <span> </span>
                <a href="https://www.vdfinremarketing.eu/privacy" className='g-text-underline clickable'>{this.getTranslation("GDPR_more")}</a>

            </CookieConsent>
            </main>
        );

    }
}

export default App;

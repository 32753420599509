export const translations = {
    en: {
        code: 'en',
        name: 'English',
        home_title: 'Welcome to the Used Cars Remarketing website.',
        home_text: 'We invite you to visit our website regularly for new sales lists and new car offers',
        login: 'LOGIN',
        register: 'REGISTER',
        teaser_title: 'Now online',
        teaser_text: 'A selection of our current offer.',
        teaser_counter_1: 'cars',
        teaser_counter_2: 'Mileage',
        teaser_counter_3: 'Month',
        how_title: 'How does it work? ',
        how_text: 'Discover our advantages',
        how_1_title: 'Registrer',
        how_1_text: 'Our sales lists are exclusively accessible to professional and registered car dealers. \n We will  verify your company documents before we can activate your access. ',
        how_2_title: 'Auction lists',
        how_2_text: '150 new cars are put on sale each week and sold through a blind bid auction to the highest bidder. Your offers are always VAT excluded. If your offer was the best offer, the car will be awarded to you. There are no auction fees or administration fees to be paid.',
        how_3_title: 'Fixed price lists',
        how_3_text: 'You didn’t get lucky on our auction lists? You can still buy your cars on our fixed price lists. Simply click the “BUY”-button and the car is yours.',
        usp_title: 'Why buy at Volkswagen D’Ieteren Finance?',
        usp_text: 'A summary of our strong points',
        usp_1_title: 'All Volkswagen Group',
        usp_1_text: 'We only sell Volkswagen, Audi, Seat, Skoda and Porsche. ',
        usp_2_title: 'Full service history',
        usp_2_text: '98% of our leasing contracts have a service contract. All of our cars are dealer serviced. ',
        usp_3_title: 'Varied mix',
        usp_3_text: 'All mileages from 6000km up to 250000km, all ages from 6 months to 6 years. From A-segment up to luxury and SUV-segment and commercial véhicles',
        usp_4_title: 'Personal approach',
        usp_4_text: '1 sales person dedicated to you to support you throughout the purchase process. ',
        usp_5_title: 'Multilingual sales team',
        usp_5_text: 'We speak Dutch, French, English and German.',
        contact_title: 'Contact Info',
        mobile_for: 'Mobile for',
        copyright: '© 2018 All rights reserved.',
        conditions: 'General conditions',
        privacy: 'Privacy',
        today: 'today',
        average: 'average',
        GDPR_accept_btn: 'Accept cookies',
        GDPR_line_1: "This website uses cookies. We use cookies to make our website work at the best, improving your online experience.",
        GDPR_line_2: "By continuing to navigate on this site, you agree to the use of cookies.",
        GDPR_more: "Learn more"

    },
    fr: {
        code: 'fr',
        name: 'Français',
        home_title: 'Bienvenue sur le site Used Cars Remarketing',
        home_text: 'Nous vous présentons régulièrement nos nouvelles listes de vente sur notre page Web.',
        login: 'LOGIN',
        register: 'INSCRIVEZ-VOUS',
        teaser_title: 'Actuellement en ligne',
        teaser_text: 'Un aperçu de notre offre actuelle',
        teaser_counter_1: 'Véhicules',
        teaser_counter_2: 'Kilométrage',
        teaser_counter_3: 'Mois',
        how_title: 'Fonctionnement',
        how_text: 'Découvrez les avantages et réponses à vos questions.',
        how_1_title: 'Inscrivez-vous',
        how_1_text: 'Nos listes de vente ne sont accessibles qu’aux professionnels de l’automobile enregistrés préalablement auprès de notre département. \n Après vérification des documents  vous aurez accès à nos services.',
        how_2_title: 'Enchère',
        how_2_text: 'Chaque semaine nous publions plus de 150 véhicules que nous attribuons à l’offre la plus élevée. Les offres se font exclusivement HTVA. \n Êtes-vous le meilleur offreur? Dès ce moment le véhicule vous est définitivement attribué. \n  Pas de coûts supplémentaires.',
        how_3_title: 'Vente à prix fixe',
        how_3_text: 'Pas de chance dans la vente aux enchères? \n Vous pouvez toujours saisir votre chance pour la liste de vente avec des voitures à prix fixe. Cliquez simplement sur \'Acheter\' et la voiture est pour vous.',
        usp_title: 'Pourquoi acheter chez nous? ',
        usp_text: 'Voyez pourquoi ci-dessous.',
        usp_1_title: 'Une gamme complète des véhicules du groupe Volkswagen',
        usp_1_text: 'Nous vendons exclusivement les marques Volkswagen, Audi, Seat, Skoda et Porsche.',
        usp_2_title: 'Carnet d’entretien complet',
        usp_2_text: 'Tous nos véhicules sont entretenu via le réseau officiel ',
        usp_3_title: 'Offre variée',
        usp_3_text: 'Véhicules entre 6000km et 250000km et âgés de 6 mois à 6 ans. \n Du segment A au segment de luxe en passant par le véhicule utilitaire. ',
        usp_4_title: 'Contact privilégié et personnel  ',
        usp_4_text: 'Une personne de contact vous êtes attribuée. ',
        usp_5_title: 'Equipe multilingue ',
        usp_5_text: 'Nous parlons le français, le néerlandais, l’anglais, l’allemand et l’espagnol.',
        contact_title: 'Info Contact',
        mobile_for: '',
        copyright: '© 2018 Tous droits réservés',
        conditions: 'Conditions générales',
        privacy: 'Privacy',
        today: 'aujourd\'hui',
        average: 'moyenne',
        GDPR_accept_btn: 'J’Accept',
        GDPR_line_1: "Ce site utilise des cookies. Nous utilisons des cookies pour que notre site web fonctionne au mieux, améliorant ainsi votre expérience en ligne.",
        GDPR_line_2: "En continuant à naviguer sur ce site, vous acceptez l'utilisation de cookies.",
        GDPR_more: "En savoir plus"
    },
    nl: {
        code: 'nl',
        name: 'Nederlands',
        home_title: 'Welkom op de Used Cars Remarketing website.',
        home_text: 'We maken regelmatig met jullie afspraak op deze website voor nieuwe verkooplijsten.',
        login: 'Log in',
        register: 'Registreer',
        teaser_title: 'Momenteel online',
        teaser_text: 'Een greep uit ons huidig aanbod.',
        teaser_counter_1: 'Wagens',
        teaser_counter_2: 'Kilometerstand',
        teaser_counter_3: 'Maand',
        how_title: 'Hoe werkt het?',
        how_text: 'Ontdek een aantal belangrijke voordelen van onze oplossing.',
        how_1_title: 'Registreer',
        how_1_text: 'Onze verkoopslijsten zijn enkel beschikbaar voor geregistreerde professionele autohandelaren. \n Na een controle van uw bedrijfsdocumenten zullen wij uw toegang activeren.',
        how_2_title: 'Veiling',
        how_2_text: 'Elke week publiceren wij meer dan 150 nieuwe wagens die aan de hoogst biedende verkocht worden. Uw biedingen zijn excl BTW. \n Bent u de hoogst biedende? \n Dan wordt de wagen aan u toegewezen. \n U betaalt geen extra veilingskosten.',
        how_3_title: 'Vaste prijslijsten',
        how_3_text: 'Geen geluk gehad via de veiling? \n Dan kan u nog steeds uw kans grijpen voor de verkooplijst met wagens aan een vaste prijs. Eenvoudig op kopen klikken en de wagen is van u.',
        usp_title: 'Waarom?',
        usp_text: 'Hieronder een overzicht van onze sterke punten',
        usp_1_title: 'Het volledige gamma van de Volkswagen Groep',
        usp_1_text: 'We verkopen enkel Volkswagen, Audi, Seat, Skoda en Porsche.',
        usp_2_title: 'Volledige onderhoudshistoriek',
        usp_2_text: 'Alle wagens zijn binnen het officiele dealersnet onderhouden.',
        usp_3_title: 'Gevarieerd aanbod',
        usp_3_text: 'Wagens van 6000 km tot 250 000 km van 6 maanden tot 6 jaar. \n Van A-segment tot het luxe segment en bedrijfswagens.',
        usp_4_title: 'Persoonlijk contact voor u',
        usp_4_text: 'U krijgt een contactpersoon toegewezen die u zal bijstaan bij elke stap van uw aankoop.',
        usp_5_title: 'Meertalig Sales Team',
        usp_5_text: 'Wij spreken Nederlands, Frans, Engels en Duits. ',
        contact_title: 'Contact Info',
        mobile_title: 'Mobiel voor',
        copyright: '© 2018 Alle rechten voorbehouden',
        conditions: 'Algemene voorwaarden',
        privacy: 'Privacy',
        today: 'vandaag',
        average: 'gemiddeld',
        GDPR_accept_btn: 'Akkoord',
        GDPR_line_1: "Deze website gebruikt cookies. We gebruiken cookies om onze website beter te laten werken en uw online ervaring te verbeteren.",
        GDPR_line_2: "Door verder te gaan met het gebruik van deze website stemt u in met het gebruik van cookies.",
        GDPR_more: "Meer weten"
    },
    de: {
        code: 'de',
        name: 'German',
        home_title: 'Herzlich Willkommen auf unserer Used Cars Remarketing Webseite.',
        home_text: 'Jede Woche finden Sie hier neue Verkaufslisten.',
        login: 'LOGIN',
        register: 'REGISTRIEREN',
        teaser_title: 'Seite Übersicht',
        teaser_text: 'Eine Auswahl aus unserem aktuellen Angebot.',
        teaser_counter_1: 'Fahrzeuge',
        teaser_counter_2: 'Kilometerstand',
        teaser_counter_3: 'Monat',
        how_title: 'Wie funktioniert es?',
        how_text: 'Entdecken Sie zahlreiche Vorteile.',
        how_1_title: 'Registrieren',
        how_1_text: 'Unsere Verkaufslisten sind exklusiv für registrierten Autohändlern vorbehalten. \n Nach Überprüfung der Firmendaten werden wir Ihnen einen Zugang anbieten. ',
        how_2_title: 'Auktion ',
        how_2_text: 'Jede Woche veröffentlichen wir mehr als 150 neue Autos, die an den Höchstbietenden verkauft werden. Ihre Angebote sind ohne Mwst. \n Sind Sie der Höchstbietende? Dann wird Ihnen das Fahrzeug zugewiesen. \n Es gibt keine weiteren Kosten.',
        how_3_title: 'Festepreislisten',
        how_3_text: 'Die Auktion hat Ihnen kein Glück gebracht? \n Dann können Sie Ihre Chance auf der Festpreisliste nutzen. Klicken Sie einfach auf "Kaufen" und das Auto wird Ihnen zugewiesen.',
        usp_title: 'Warum bei uns kaufen? ',
        usp_text: 'Wir zeigen Ihnen eine Übersicht unserer stärksten Punkte: ',
        usp_1_title: 'Die gesamte Palette des Volkswagen Konzerns',
        usp_1_text: 'Wir verkaufen nur Volkswagen, Audi, Seat, Skoda und Porsche.',
        usp_2_title: 'Komplette Fahrzeughistorie',
        usp_2_text: 'Alle unsere Autos sind scheckheftgepflegt. ',
        usp_3_title: 'Großes Angebot ',
        usp_3_text: 'Autos von 6000 km bis 250 000 km und von 6 Monaten bis 6 Jahren. \n Vom A-Segment bis zum Luxussegment sowie Nutzfahrzeugen.',
        usp_4_title: 'Persönlicher Kontakt für Sie',
        usp_4_text: 'Ihnen wird eine Kontaktperson zugewiesen, die Sie bei jedem Schritt Ihres Kaufs unterstützt.',
        usp_5_title: 'Vielsprachiges Verkaufsteam',
        usp_5_text: 'Wir sprechen Deutsch, Niederländisch, Französisch, Englisch und Spanisch.',
        contact_title: 'Contact Info',
        mobile_for: '',
        copyright: '© 2018 Alle Rechten vorbehalten',
        conditions: 'Algemeine Geschäftsbedingungen',
        privacy: 'Privacy',
        today: 'heute',
        average: 'durchschnittlich',
        GDPR_accept_btn: 'Accept cookies',
        GDPR_line_1: "This website uses cookies. We use cookies to make our website work at the best, improving your online experience.",
        GDPR_line_2: "By continuing to navigate on this site, you agree to the use of cookies.",
        GDPR_more: "Learn more"
    },
    hr: {
        code: 'hr',
        name: 'Hrvatski',
        home_title: 'Dobro došli na web-stranicu za remarketing rabljenih automobila.',
        home_text: 'Redovito ćemo se sastajati na ovoj stranici radi novih prodajnih popisa.',
        login: 'PRIJAVA',
        register: 'REGISTRACIJA',
        teaser_title: 'Trenutačno na vezi ',
        teaser_text: 'Izbor iz naše trenutačne ponude',
        teaser_counter_1: 'Automobili',
        teaser_counter_2: 'Kilometraža',
        teaser_counter_3: 'Mjesec',
        how_title: 'Kako funkcionira?',
        how_text: 'Otkrijte više važnih pogodnosti našeg rješenja.',
        how_1_title: 'Registracija',
        how_1_text: 'Naši su prodajni popisi dostupni samo registriranim profesionalnim trgovcima automobila. \n Nakon provjere dokumenata vaše tvrtke aktivirat ćemo vaša prava pristupa.',
        how_2_title: 'Dražba',
        how_2_text: 'Svakog tjedna predstavljamo više od 150 novih automobila koje prodajemo najboljem ponuđaču. Vaše ponude ne uključuju PDV. \n Jeste li vi najbolji ponuđač? \n U tom se slučaju automobil dodjeljuje vama. \n Ne plaćate nikakve dodatne troškove dražbe.',
        how_3_title: 'Zadani cjenici',
        how_3_text: 'Niste imali sreće na dražbi? \n I dalje možete uhvatiti priliku na prodajnom popisu s automobilima po fiksnim cijenama. \n Samo kliknite „Kupi“ i automobil je vaš.',
        usp_title: 'Zašto kupiti od nas? ',
        usp_text: 'U nastavku je pregled naših prednosti',
        usp_1_title: 'Puni raspon Grupe Volkswagen',
        usp_1_text: 'Prodajemo samo marke Volkswagen, Audi, SEAT, ŠKODA i Porsche.',
        usp_2_title: 'Povijest potpune usluge',
        usp_2_text: 'Svi se automobili servisiraju u mreži službenih dobavljača.',
        usp_3_title: 'Raznolika ponuda',
        usp_3_text: 'Automobili kilometraže od 6.000 km do 250.000 km, od 6 mjeseci do 6 godina. \n Od A-kategorije do luksuzne kategorije te službena vozila.',
        usp_4_title: 'Osobni kontakt',
        usp_4_text: 'Dodijelit ćemo vam osobu za kontakt koja će vas voditi kroz svaki korak kupnje.',
        usp_5_title: 'Višejezični prodajni tim',
        usp_5_text: 'Govorimo nizozemski, francuski, engleski i njemački.',
        contact_title: 'Podaci za kontakt',
        mobile_for: '',
        copyright: '© 2018 Sva prava pridržana',
        conditions: 'Uvjeti i odredbe i',
        privacy: 'Zaštita privatnosti',
        today: 'danas',
        average: 'prosječan',
        GDPR_accept_btn: 'Accept cookies',
        GDPR_line_1: "This website uses cookies. We use cookies to make our website work at the best, improving your online experience.",
        GDPR_line_2: "By continuing to navigate on this site, you agree to the use of cookies.",
        GDPR_more: "Learn more"
    },
    et: {
        code: 'et',
        name: 'Eesti',
        home_title: 'Tere tulemast kasutatud autode edasimüügi veebilehele!',
        home_text: 'Kuvame sellel veebilehel regulaarselt uusi müügiloendeid.',
        login: 'SISSELOGIMINE',
        register: 'REGISTREERUMINE',
        teaser_title: 'Praegu veebis ',
        teaser_text: 'Valik meie praegustest pakkumistest.',
        teaser_counter_1: 'Autod',
        teaser_counter_2: 'Läbisõit',
        teaser_counter_3: 'Kuu',
        how_title: 'Kuidas see töötab?',
        how_text: 'Avastage meie lahenduse paljud olulised eelised.',
        how_1_title: 'Registreerumine',
        how_1_text: 'Meie müügiloendid on saadaval ainult registreeritud professionaalsetele autokaupmeestele. \n Aktiveerime teie juurdepääsuõigused pärast ettevõtte dokumentide kontrollimist.',
        how_2_title: 'Oksjon',
        how_2_text: 'Esitleme igal nädalal 150 uut autot, mis müüakse kõrgeima pakkumise tegijale. Teie pakkumised ei sisalda käibemaksu. \n Kas teete kõrgeima pakkumise? \n Sel juhul saate auto endale. \n Teil ei tule tasuda täiendavaid oksjonikulusid.',
        how_3_title: 'Fikseeritud hinnaga loendid',
        how_3_text: 'Oksjonil ei ole õnne? \n Saate endale sobiva variandi leida meie müügiloendist, kus on fikseeritud hinnaga autod. \n Klõpsake lihtsalt nupul „Osta“ ja auto on teie.',
        usp_title: 'Miks osta meilt? ',
        usp_text: 'Allpool on ülevaade meie tugevustest',
        usp_1_title: 'Kõik Volkswagen Groupi autod',
        usp_1_text: 'Müüme vaid Volkswagenit, Audit, SEATI, ŠKODAT ja Porchet.',
        usp_2_title: 'Täielik hooldusajalugu',
        usp_2_text: 'Kõiki autosid hooldatakse ametlike edasimüüjate juures.',
        usp_3_title: 'Erinevad pakkumised',
        usp_3_text: 'Autod läbisõiduga 6000 km kuni 250 000 km ning 6 kuu kuni 6 aasta vanused. \n A-kategooriast luksuskategooria ja ametiautodeni',
        usp_4_title: 'Isiklik kontakt',
        usp_4_text: 'Teile määratakse kontaktisik, kes aitab teid igal ostuetapil.',
        usp_5_title: 'Mitmekeelne müügimeeskond',
        usp_5_text: 'Räägime hollandi, prantsuse, inglise ja saksa keelt. ',
        contact_title: 'Kontaktteave',
        mobile_for: '',
        copyright: '© 2018 Kõik õigused kaitstud',
        conditions: 'Tingimused ja ',
        privacy: 'Privaatsus',
        today: 'täna',
        average: 'keskmine',
        GDPR_accept_btn: 'Accept cookies',
        GDPR_line_1: "This website uses cookies. We use cookies to make our website work at the best, improving your online experience.",
        GDPR_line_2: "By continuing to navigate on this site, you agree to the use of cookies.",
        GDPR_more: "Learn more"
    },
    es: {
        code: 'es',
        name: 'Espanol',
        home_title: 'Bienvenido/a al sitio web de Used Cars Remarketing.',
        home_text: 'En este sitio web podrá encontrar regularmente nuevas listas de coches en venta.',
        login: 'INICIAR SESIÓN',
        register: 'REGISTRARSE',
        teaser_title: 'Actualmente en línea ',
        teaser_text: 'Una selección de nuestra oferta actual.',
        teaser_counter_1: 'Coches',
        teaser_counter_2: 'Kilometraje',
        teaser_counter_3: 'Mes',
        how_title: '¿Cómo funciona el sistema?',
        how_text: 'Descubra diversas ventajas importantes de nuestra solución.',
        how_1_title: 'Registro',
        how_1_text: 'Las listas de coches en venta solo estarán disponibles para concesionarios de coches registrados. \n Tras verificar la documentación de su concesionario, activaremos sus derechos de acceso.',
        how_2_title: 'Subastas',
        how_2_text: 'Cada semana subastaremos más de 150 nuevos vehículos que se venderán al mejor postor. Las pujas no incluyen el IVA correspondiente. \n ¿Su puja fue la más alta? \n En ese caso, se le adjudicará el coche. \n No tendrá que pagar ningún tipo de tasa adicional de subasta.',
        how_3_title: 'Listas de coches con precios fijos',
        how_3_text: '¿No tuvo suerte en la subasta? \n Quizás pueda encontrar lo que busca en la lista de coches en venta con precios fijos. \n Para hacerse con un coche, solo tiene que hacer clic en “Comprar”.',
        usp_title: 'Razones para comprar nuestros coches ',
        usp_text: 'A continuación puede encontrar un resumen de nuestras fortalezas.',
        usp_1_title: 'Toda la gama del grupo Volkswagen',
        usp_1_text: 'Únicamente vendemos vehículos Volkswagen, Audi, SEAT, ŠKODA y Porsche.',
        usp_2_title: 'Historial completo de mantenimiento',
        usp_2_text: 'El mantenimiento de todos los coches se ha llevado a cabo en la red de concesionarios oficiales.',
        usp_3_title: 'Oferta variada',
        usp_3_text: 'Coches con un kilometraje de entre 6.000 y 250.000 km y una antigüedad de entre 6 meses y 6 años. \n Puede encontrar desde coches del segmento A hasta coches de lujo o de empresa.',
        usp_4_title: 'Trato personal',
        usp_4_text: 'Se le asignará a una persona de contacto que le guiará a lo largo de los pasos del proceso de compra.',
        usp_5_title: 'Equipo comercial multilingüe',
        usp_5_text: 'Hablamos neerlandés, francés, inglés y alemán. ',
        contact_title: 'Información de contacto',
        mobile_for: '',
        copyright: '© 2018 Todos los derechos reservados',
        conditions: 'Términos, condiciones ',
        privacy: 'Política de privacidad',
        today: 'hoy',
        average: 'promedio',
        GDPR_accept_btn: 'Accept cookies',
        GDPR_line_1: "This website uses cookies. We use cookies to make our website work at the best, improving your online experience.",
        GDPR_line_2: "By continuing to navigate on this site, you agree to the use of cookies.",
        GDPR_more: "Learn more"
    },
    el: {
        code: 'el',
        name: 'Ελληvικά',
        home_title: 'Καλώς ήρθατε στον ιστότοπο μεταπώλησης μεταχειρισμένων αυτοκινήτων',
        home_text: 'Μπορείτε να επισκέπτεστε ανά τακτά διαστήματα τον ιστότοπο για να βλέπετε τις νέες λίστες πωλήσεων.',
        login: 'ΣΥΝΔΕΣΗ',
        register: 'ΕΓΓΡΑΦΗ',
        teaser_title: 'Διαθέσιμα στον ιστότοπο ',
        teaser_text: 'Μια επιλογή από τα οχήματα που διατίθενται αυτή τη στιγμή.',
        teaser_counter_1: 'Αυτοκίνητα',
        teaser_counter_2: 'Χιλιόμετρα',
        teaser_counter_3: 'Μήνας',
        how_title: 'Πώς λειτουργεί; ',
        how_text: 'Ανακαλύψτε τα σημαντικά πλεονεκτήματα που προσφέρει η λύση μας.',
        how_1_title: 'Εγγραφη',
        how_1_text: 'Οι λίστες πωλήσεων είναι διαθέσιμες μόνο σε εγγεγραμμένους επαγγελματίες εμπόρους αυτοκινήτων. \n Αφού ελέγξουμε τα έγγραφα της επιχείρησής σας, θα σας εκχωρήσουμε διακαιώματα πρόσβασης.',
        how_2_title: 'Δημοπρασία',
        how_2_text: 'Κάθε εβδομάδα παρουσιάζουμε 150 νέα αυτοκίνητα, τα οποία πωλούνται σε όποιον υποβάλει την υψηλότερη προσφορά. Οι προσφορές που υποβάλετε δεν περιλαμβάνουν τον ΦΠΑ. \n Έχετε υποβάλει την υψηλότερη προσφορά; \n Αν ναι, τότε το αυτοκίνητο είναι δικό σας. \n Δεν υπάρχουν επιπλέον χρεώσεις για τη δημοπρασία.',
        how_3_title: 'Λίστες οχημάτων με προκαθορισμένη τιμή',
        how_3_text: 'Δεν τα καταφέρατε στη δημοπρασία; \n Προλαβαίνετε ακόμα να επωφεληθείτε από τη λίστα που περιλαμβάνει οχήματα με προκαθορισμένη τιμή. \n Απλώς πατήστε «Αγορά»και το αυτοκίνητο θα γίνει δικό σας.',
        usp_title: 'Γιατί να αγοράσετε αυτοκίνητο από την εταιρεία μας; ',
        usp_text: 'Δείτε παρακάτω σε τι υπερτερούμε',
        usp_1_title: 'Οχήματα από όλες τις εταιρείες του Ομίλου Volkswagen',
        usp_1_text: 'Πουλάμε μόνο οχήματα Volkswagen, Audi, SEAT, ŠKODA και Porsche.',
        usp_2_title: 'Πλήρες ιστορικό σέρβις',
        usp_2_text: 'Το σέρβις όλων των αυτοκινήτων γίνεται εντός του δικτύου των επίσημων εμπόρων.',
        usp_3_title: 'Ποικιλία μοντέλων',
        usp_3_text: 'Αυτοκίνητα που έχουν διανύσει από 6.000 έως 250.000 χιλιόμετρα, ηλικίας από 6 μηνών έως 6 ετών. \n Από αυτοκίνητα κατηγορίας Α έως πολυτελή και εταιρικά αυτοκίνητα.',
        usp_4_title: 'Προσωπική επικοινωνία',
        usp_4_text: 'Θα έχετε το δικό σας πρόσωπο επικοινωνίας, το οποίο θα σας καθοδηγήσει σε όλα τα βήματα της αγοράς.',
        usp_5_title: 'Πολύγλωσση ομάδα πωλήσεων',
        usp_5_text: 'Μιλάμε Ολλανδικά, Γαλλικά, Αγγλικά και Γερμανικά. ',
        contact_title: 'Στοιχεία επικοινωνίας',
        mobile_for: '',
        copyright: '© 2018 Με την επιφύλαξη παντός δικαιώματος ',
        conditions: 'Όροι και προϋποθέσεις',
        privacy: 'Απόρρητο',
        today: 'σήμερα',
        average: 'μέση τιμή',
        GDPR_accept_btn: 'Accept cookies',
        GDPR_line_1: "This website uses cookies. We use cookies to make our website work at the best, improving your online experience.",
        GDPR_line_2: "By continuing to navigate on this site, you agree to the use of cookies.",
        GDPR_more: "Learn more"
    },
    da: {
        code: 'da',
        name: 'Dansk',
        home_title: 'Velkommen på webstedet Used Cars Remarketing.',
        home_text: 'Vi træffer regelmæssigt aftale med jer om nye salgslister på dette websted.',
        login: 'LOGIN',
        register: 'REGISTRER DIG',
        teaser_title: 'Du er online ',
        teaser_text: 'Et udpluk af vores tilbud i dag.',
        teaser_counter_1: 'Biler',
        teaser_counter_2: 'Kilometerstand',
        teaser_counter_3: 'Måned',
        how_title: 'Hvordan fungerer det?',
        how_text: 'Opdag et antal vigtige fordele ved vores løsning.',
        how_1_title: 'Registrer dig',
        how_1_text: 'Der er kun adgang til vores salgslister for registrerede professionelle autoforhandlere. \n Efter en kontrol af dine virksomhedsdokumenter aktiverer vi din adgang.',
        how_2_title: 'Auktion',
        how_2_text: 'Hver uge offentliggør vi mere end 150 nye biler, der sælges til de højstbydende. Dine bud er uden moms. \n Er du den højstbydende? \n  Så tilfalder bilen dig. \n Du betaler ingen ekstra auktionsomkostninger.',
        how_3_title: 'Faste prislister',
        how_3_text: 'Havde du ikke held ved auktionen? \n Så kan du stadig gribe chancen med salgslisten over biler til en fast pris. \n Klik bare på ”Køb”, og bilen er din.',
        usp_title: 'Hvorfor købe hos os? ',
        usp_text: 'Herunder findes der en oversigt over vores stærke argumenter',
        usp_1_title: 'Volkswagengruppens komplette sortiment',
        usp_1_text: 'Vi sælger kun Volkswagen, Audi, SEAT, ŠKODA og Porsche',
        usp_2_title: 'Fuldstændig vedligeholdelseshistorik',
        usp_2_text: 'Alle biler er blevet vedligeholdt af autoriserede forhandlere.',
        usp_3_title: 'Varieret tilbud',
        usp_3_text: 'Biler fra 6.000 km til 250.000 km fra 6 måneder til 6 år. \n Fra A-segmentet til luksussegmentet og erhvervskøretøjer ',
        usp_4_title: 'Din personlige kontakt',
        usp_4_text: 'Du får tildelt en personlig kontaktperson, som vil hjælpe dig på hvert trin af dit køb.',
        usp_5_title: 'Flersproget salgsteam',
        usp_5_text: 'Vi taler hollandsk, fransk, engelsk og tysk. ',
        contact_title: 'Kontaktoplysninger',
        mobile_for: '',
        copyright: '© 2018 Alle rettigheder forbeholdt',
        conditions: 'Almindelige betingelser',
        privacy: 'Databeskyttelse',
        today: 'i dag',
        average: 'gennemsnit',
        GDPR_accept_btn: 'Accept cookies',
        GDPR_line_1: "This website uses cookies. We use cookies to make our website work at the best, improving your online experience.",
        GDPR_line_2: "By continuing to navigate on this site, you agree to the use of cookies.",
        GDPR_more: "Learn more"
    },
    cs: {
        code: 'cs',
        name: 'Česky',
        home_title: 'Vítejte na webových stránkách pro prodej použitých vozů.',
        home_text: 'Na těchto webových stránkách budeme pravidelně zveřejňovat nové prodejní seznamy.',
        login: 'PŘIHLÁSIT SE',
        register: 'REGISTRACE',
        teaser_title: 'Aktuálně online ',
        teaser_text: 'Výběr z naší aktuální nabídky.',
        teaser_counter_1: 'Vozy',
        teaser_counter_2: 'Počet najetých kilometrů',
        teaser_counter_3: 'Měsíc',
        how_title: 'Jak to funguje?',
        how_text: 'Objevte celou řadu značných výhod našeho řešení.',
        how_1_title: 'Registrace',
        how_1_text: 'Naše prodejní seznamy jsou k dispozici pouze registrovaným profesionálním prodejcům automobilů. \n Po kontrole vašich firemních dokumentů aktivujeme vaše přístupová práva.',
        how_2_title: 'Aukce',
        how_2_text: 'Každý týden představujeme více než 150 nových vozů, které jsou prodány dražitelům s nejvyšší nabídkou. Vaše nabídky nezahrnují DPH. \n Předložili jste nejvyšší nabídku? \n V takovém případě získáte daný vůz. \n Neplatíte žádné další náklady na aukci.',
        how_3_title: 'Pevné ceníky',
        how_3_text: 'Neuspěli jste v aukci? \n Stále máte možnost využít svou šanci v rámci prodejního seznamu, který obsahuje vozy za pevně stanovené ceny. \n Jednoduše klikněte na tlačítko „Koupit“ a vůz je váš.',
        usp_title: 'Proč nakupovat od nás? ',
        usp_text: 'Níže uvádíme přehled našich silných stránek',
        usp_1_title: 'Kompletní sortiment od koncernu Volkswagen',
        usp_1_text: 'Prodáváme pouze vozy značek Volkswagen, Audi, SEAT, ŠKODA a Porsche.',
        usp_2_title: 'Úplná servisní historie',
        usp_2_text: 'Všechny vozy jsou servisovány v síti oficiálních dealerů.',
        usp_3_title: 'Rozmanitá nabídka',
        usp_3_text: 'Auta s nájezdem od 6 000 km do 250 000 km a se stářím od 6 měsíců do 6 let. \n Od základní kategorie až po kategorii luxusních automobilů a firemní vozy.',
        usp_4_title: 'Osobní kontakt',
        usp_4_text: 'Bude vám přidělena kontaktní osoba, která vás provede každým krokem vašeho nákupu.',
        usp_5_title: 'Vícejazyčný prodejní tým',
        usp_5_text: 'Mluvíme holandsky, francouzsky, anglicky a německy. ',
        contact_title: 'Kontaktní informace',
        mobile_for: '',
        copyright: '© 2018 Všechna práva vyhrazena',
        conditions: 'Obchodní podmínky',
        privacy: 'Ochrana osobních údajů.',
        today: 'dnes',
        average: 'průměrný',
        GDPR_accept_btn: 'Accept cookies',
        GDPR_line_1: "This website uses cookies. We use cookies to make our website work at the best, improving your online experience.",
        GDPR_line_2: "By continuing to navigate on this site, you agree to the use of cookies.",
        GDPR_more: "Learn more"
    },
    bg: {
        code: 'bg',
        name: 'BбългарскиG',
        home_title: 'Добре дошли в уеб сайта за ремаркетинг на употребявани автомобили.',
        home_text: 'В този уеб сайт редовно ще обявяваме списъци с нови обяви за продажба.',
        login: 'ВЛИЗАНЕ',
        register: 'РЕГИСТРАЦИЯ',
        teaser_title: 'В момента онлайн ',
        teaser_text: 'Избор от текущите ни предложения.',
        teaser_counter_1: 'Автомобили',
        teaser_counter_2: 'Километраж',
        teaser_counter_3: 'месец',
        how_title: 'Как работи?',
        how_text: 'Открийте разнообразни важни предимства, осигурени от нашето решение.',
        how_1_title: 'Регистрация',
        how_1_text: 'Списъците ни с оферти за продажба са достъпни само за регистрирани професионални търговци на автомобили. \n След като проверим документите на фирмата ви, ще ви дадем право на достъп.',
        how_2_title: 'Търг',
        how_2_text: 'Всяка седмица представяме над 150 нови автомобили, които се продават на потребителя, обявил най-висока цена. Вашите оферти не включват ДДС. \n Вашата ли предложена цена е най-висока? \n Ако това е така, автомобилът отива при вас. \n Няма да заплащате никакви допълнителни разходи по търга.',
        how_3_title: 'Фиксирани ценови списъци',
        how_3_text: 'Нямате късмет с търга? \n Въпреки това можете да опитате късмета си със списъка с оферти за продажба на автомобили на фиксирани цени. \n Трябва само да натиснете бутона „Купи“ и автомобилът ще стане ваш.',
        usp_title: 'Защо да купите от нас? ',
        usp_text: 'По-долу можете да прегледате силните ни страни',
        usp_1_title: 'Пълната гама, предлагана от Volkswagen Group',
        usp_1_text: 'Имаме право да продаваме само марките Volkswagen, Audi, SEAT, ŠKODA и Porsche.',
        usp_2_title: 'Пълна сервизна история',
        usp_2_text: 'Всички автомобили са сервизирани в мрежата ни официални търговски представители.',
        usp_3_title: 'Разнообразни предложения',
        usp_3_text: 'Автомобили с километраж от 6 000 км до 250 000 км, от 6 месеца до 6 години. \n От категория A до луксозен клас и служебни автомобили',
        usp_4_title: 'Личен контакт',
        usp_4_text: 'Ще бъде определено лице за контакт от нашия екип, който ще ви окаже съдействие във всички етапи на покупката.',
        usp_5_title: 'Екип продажби, предоставящи обслужване на много езици',
        usp_5_text: 'Владеем холандски, френски, английски и немски език. ',
        contact_title: 'Информация за контакт',
        mobile_for: '',
        copyright: '© 2018 Всички права запазени',
        conditions: 'Условия ',
        privacy: 'поверителност',
        today: 'днес',
        average: 'средно аритметично',
        GDPR_accept_btn: 'Accept cookies',
        GDPR_line_1: "This website uses cookies. We use cookies to make our website work at the best, improving your online experience.",
        GDPR_line_2: "By continuing to navigate on this site, you agree to the use of cookies.",
        GDPR_more: "Learn more"
    },
    sv: {
        code: 'sv',
        name: 'Svenska',
        home_title: 'Välkommen till webbplatsen Used Cars Remarketing.',
        home_text: 'Besök vår webbplats regelbundet, vi uppdaterar våra försäljningslistor fortlöpande.',
        login: 'LOGGA IN',
        register: 'REGISTRERA',
        teaser_title: 'Just nu online ',
        teaser_text: 'Ett urval av vårt utbud just nu.',
        teaser_counter_1: 'Bilar',
        teaser_counter_2: 'Mätarställning',
        teaser_counter_3: 'Månad',
        how_title: 'Så här fungerar det ',
        how_text: 'Här beskriver vi några viktiga fördelar med vår lösning.',
        how_1_title: 'Registrera',
        how_1_text: 'Våra försäljningslistor är endast tillgängliga för yrkesaktiva bilhandlare som har skapat ett konto hos oss. \n Du får tillgång till dessa data när vi har kontrollerat dina företagsuppgifter.',
        how_2_title: 'Auktion',
        how_2_text: 'Vi lägger varje vecka ut mer än 150 nya bilar till försäljning till högstbjudande. Beloppen i budgivningen är exklusive moms. \n Har du det högsta budet? \n Då är bilen din. \n Du betalar inga extra auktionsavgifter.',
        how_3_title: 'Fasta prislistor',
        how_3_text: 'Gick inte auktionen din väg? \n Vi har också en förteckning med bilar till försäljning till ett fast pris. \n Där klickar du bara på ”Köp” så är bilen din.',
        usp_title: 'Därför är det bra att köpa hos oss ',
        usp_text: 'Nedan kan du läsa om fördelarna med att köpa hos oss',
        usp_1_title: 'Volkswagen Groeps alla märken',
        usp_1_text: 'Vi säljer bara Volkswagen, Audi, SEAT, ŠKODA och Porsche.',
        usp_2_title: 'Fullständig servicehistorik',
        usp_2_text: 'Alla bilar har servats av originalverkstäder.',
        usp_3_title: 'Varierat utbud',
        usp_3_text: 'Bilar från 6 000 km till 250 000 km, från 6 månader till 6 år. \n Allt från bilar i A-segmentet till lyxmodeller och företagsbilar',
        usp_4_title: 'Fast kontaktperson',
        usp_4_text: 'Vi utser en kontaktperson till dig som hjälper dig genom alla skeden av köpet.',
        usp_5_title: 'Flerspråkiga säljare',
        usp_5_text: 'Våra säljare talar nederländska, franska, engelska och tyska. ',
        contact_title: 'Kontakt',
        mobile_for: '',
        copyright: '© 2018 Förbehållet alla rättigheter',
        conditions: 'Allmänna villkor',
        privacy: 'Personuppgiftsskydd',
        today: 'i dag',
        average: 'medel',
        GDPR_accept_btn: 'Accept cookies',
        GDPR_line_1: "This website uses cookies. We use cookies to make our website work at the best, improving your online experience.",
        GDPR_line_2: "By continuing to navigate on this site, you agree to the use of cookies.",
        GDPR_more: "Learn more"
    },
    sr: {
        code: 'sr',
        name: 'Српски',
        home_title: 'Dobro došli na veb-sajt za prodaju polovnih automobila.',
        home_text: 'Redovno ćemo se sastajati na ovom veb-sajtu da bismo objavljivali nove liste za prodaju.',
        login: 'PRIJAVA',
        register: 'REGISTRACIJA',
        teaser_title: 'Trenutno onlajn ',
        teaser_text: 'Izbor iz naše trenutne ponude',
        teaser_counter_1: 'Automobili',
        teaser_counter_2: 'Kilometraža',
        teaser_counter_3: 'Mesec',
        how_title: 'Kako ovo funkcioniše?',
        how_text: 'Otkrijte mnoštvo važnih prednosti našeg rešenja.',
        how_1_title: 'Registracija',
        how_1_text: 'Naše liste za prodaju su dostupne samo registrovanim profesionalnim prodavcima automobila. \n Nakon što proverimo vašu poslovnu dokumentaciju, aktiviraćemo vam prava pristupa.',
        how_2_title: 'Aukcija',
        how_2_text: 'Svake nedelje predstavljamo preko 150 automobila koji se prodaju osobi koja ponudi više. Ponude ne obuhvataju PDV. \n Da li ste vi ponudili najviše? \n U tom slučaju, automobil se dodeljuje vama. \n Ne plaćate nikakve dodatne troškove aukcije.',
        how_3_title: 'Liste sa fiksnim cenama',
        how_3_text: 'Niste imali sreće na aukciji? \n Ipak imate priliku da kupite vozilo na listi za prodaju koja sadrži automobile po fiksnim cenama. \n Samo kliknite na „Kupi“ i auto je vaš.',
        usp_title: 'Zašto treba da kupujete od nas? ',
        usp_text: 'U nastavku se nalazi pregled prednosti koje nudimo',
        usp_1_title: 'Ceo asortiman iz Volkswagen grupe',
        usp_1_text: 'Prodajemo samo Volkswagen, Audi, SEAT, ŠKODA i Porsche vozila.',
        usp_2_title: 'Kompletna istorija servisiranja',
        usp_2_text: 'Svi automobili su servisirani u mreži ovlašćenih dilera.',
        usp_3_title: 'Raznovrsna ponuda',
        usp_3_text: 'Automobili sa kilometražom od 6.000 km do 250.000 km, stari od 6 meseci do 6 godina. \n Od automobila A kategorije do luksuznih i polovnih vozila',
        usp_4_title: 'Lični kontakt',
        usp_4_text: 'Dodelićemo vam kontakt osobu koja će vas provesti kroz svaki korak kupovine.',
        usp_5_title: 'Višejezični tim za prodaju',
        usp_5_text: 'Govorimo holandski, francuski, engleski i nemački. ',
        contact_title: 'Kontakt informacije',
        mobile_for: '',
        copyright: '© 2018 Sva prava zadržana Uslovi ',
        conditions: 'Odredbe ',
        privacy: 'Privatnost',
        today: 'danas',
        average: 'prosek',
        GDPR_accept_btn: 'Accept cookies',
        GDPR_line_1: "This website uses cookies. We use cookies to make our website work at the best, improving your online experience.",
        GDPR_line_2: "By continuing to navigate on this site, you agree to the use of cookies.",
        GDPR_more: "Learn more"
    },
    sl: {
        code: 'sl',
        name: 'Slovenščina',
        home_title: 'Dobrodošli na spletni strani za prodajo rabljenih avtomobilov.',
        home_text: 'Na tej spletni strani bomo redno objavljali nove prodajne sezname.',
        login: 'PRIJAVA',
        register: 'REGISTRACIJA',
        teaser_title: 'Trenutno v spletu ',
        teaser_text: 'Izbor iz naše trenutne ponudbe.',
        teaser_counter_1: 'Avtomobili',
        teaser_counter_2: 'Prevoženi kilometri',
        teaser_counter_3: 'Mesec',
        how_title: 'Kako deluje?',
        how_text: 'Poglejte, kakšne so prednosti naše rešitve.',
        how_1_title: 'Registracija',
        how_1_text: 'Naši prodajni seznami so na voljo samo registriranim profesionalnim trgovcem z avtomobili. \n Ko bomo preverili vaše poslovne dokumente, vam bomo aktivirali pravice dostopa.',
        how_2_title: 'Dražba',
        how_2_text: 'Vsak teden predstavimo več kot 150 novih avtomobilov, ki se prodajo najboljšemu ponudniku. Vaše ponudbe ne vključujejo DDV-ja. \n Ali ste najboljši ponudnik? \n Potem je avtomobil vaš. \n Ne plačate nobenih dodatnih stroškov dražbe.',
        how_3_title: 'Fiksni ceniki',
        how_3_text: 'Niste imeli sreče na dražbi? \n Še vedno lahko izkoristite svojo priložnost na prodajnem seznamu avtomobilov po fiksnih cenah. \n Preprosto kliknite ‘Kupi’ in avto je vaš.',
        usp_title: 'Zakaj kupiti pri nas? ',
        usp_text: 'To so naše prednosti',
        usp_1_title: 'Celotna serija vozil skupine Volkswagen',
        usp_1_text: 'Prodajamo le vozila Volkswagen, Audi, SEAT, ŠKODA in Porsche.',
        usp_2_title: 'Celotna zgodovina servisiranja',
        usp_2_text: 'Vsi avtomobili se servisirajo v mreži uradnih trgovcev.',
        usp_3_title: 'Raznolika ponudba',
        usp_3_text: 'Avtomobili s 6.000 do 250.000 prevoženih kilometrov, starih od 6 mesecev do 6 let. \n Od kategorije A do luksuzne kategorije in službenih avtomobilov',
        usp_4_title: 'Osebni stik',
        usp_4_text: 'Dodelimo vam kontaktno osebo, ki vas vodi skozi vse korake vašega nakupa.',
        usp_5_title: 'Prodajna ekipa, ki govori več jezikov',
        usp_5_text: 'Govorimo dansko, francosko, angleško in nemško. ',
        contact_title: 'Kontaktne informacije',
        mobile_for: '',
        copyright: '© 2018 Vse pravice pridržane',
        conditions: 'Pogoji in določila',
        privacy: 'Zasebnost',
        today: 'danes',
        average: 'povprečje',
        GDPR_accept_btn: 'Accept cookies',
        GDPR_line_1: "This website uses cookies. We use cookies to make our website work at the best, improving your online experience.",
        GDPR_line_2: "By continuing to navigate on this site, you agree to the use of cookies.",
        GDPR_more: "Learn more"
    },
    sk: {
        code: 'sk',
        name: 'Slovenský',
        home_title: 'Vitajte na webovej lokalite Opätovný predaj ojazdených automobilov.',
        home_text: 'Na tejto webovej lokalite vám budeme pravidelne poskytovať nové predajné zoznamy.',
        login: 'PRIHLÁSENIE',
        register: 'REGISTRÁCIA',
        teaser_title: 'Momentálne online ',
        teaser_text: 'Výber z našej aktuálnej ponuky.',
        teaser_counter_1: 'Automobily',
        teaser_counter_2: 'Počet najazdených kilometrov',
        teaser_counter_3: 'Mesiac',
        how_title: 'Ako to funguje? ',
        how_text: 'Objavte rad dôležitých výhod, ktoré naše riešenie zahŕňa.',
        how_1_title: 'Registrácia',
        how_1_text: 'Naše predajné zoznamy sú k dispozícii len pre zaregistrovaných profesionálnych predajcov automobilov. \n Po kontrole vašich firemných dokumentov vám aktivujeme prístupové práva.',
        how_2_title: 'Aukcia',
        how_2_text: 'Každý týždeň predstavujeme viac ako 150 nových automobilov, ktoré sa predávajú uchádzačom s najvyššou cenovou ponukou. Vaše cenové ponuky nezahŕňajú DPH. \n Ste uchádzač s najvyššou cenovou ponukou? \n V takom prípade sa automobil pridelí vám. \n Neplatíte žiadne ďalšie náklady na aukciu.',
        how_3_title: 'Zoznamy s pevnými cenami',
        how_3_text: 'Nemali ste v aukcii šťastie? \n Ešte stále sa môžete chopiť príležitosti v predajnom zozname, ktorý obsahuje automobily za pevne stanovené ceny. \n Stačí kliknúť na „Kúpiť“ a automobil je váš.',
        usp_title: 'Prečo nakupovať od nás? ',
        usp_text: 'Nižšie uvádzame prehľad našich silných stránok',
        usp_1_title: 'Celý sortiment od spoločnosti Volkswagen Group',
        usp_1_text: 'Predávame len automobily značky Volkswagen, Audi, SEAT, ŠKODA a Porsche.',
        usp_2_title: 'Úplná servisná história',
        usp_2_text: 'Servis všetkých automobilov sa realizuje v rámci siete oficiálnych predajcov.',
        usp_3_title: 'Pestrá ponuka',
        usp_3_text: 'Automobily s rôznym počtom najazdených kilometrov, a to v rozsahu od 6 000 km do 250 000 km, ktoré sú staré 6 mesiacov až 6 rokov. \n Od kategórie A až po luxusnú kategóriu a firemné vozidlá',
        usp_4_title: 'Osobný kontakt',
        usp_4_text: 'Bude vám pridelená kontaktná osoba, ktorá vás bude sprevádzať každým krokom nákupu.',
        usp_5_title: 'Mnohojazyčný predajný tím',
        usp_5_text: 'Hovoríme holandsky, francúzsky, anglicky a nemecky. ',
        contact_title: 'Kontaktné údaje',
        mobile_for: '',
        copyright: '© 2018 Všetky práva vyhradené',
        conditions: 'Obchodné podmienky ',
        privacy: 'Ochrany osobných údajov',
        today: 'dnes',
        average: 'priemerný',
        GDPR_accept_btn: 'Accept cookies',
        GDPR_line_1: "This website uses cookies. We use cookies to make our website work at the best, improving your online experience.",
        GDPR_line_2: "By continuing to navigate on this site, you agree to the use of cookies.",
        GDPR_more: "Learn more"
    },
    ru: {
        code: 'ru',
        name: 'русский',
        home_title: 'Приветствуем вас на сайте продажи подержанных автомобилей',
        home_text: 'На этом сайте мы будем регулярно размещать информацию о новых поступлениях.',
        login: 'ЛОГИН',
        register: 'РЕГИСТРАЦИЯ',
        teaser_title: 'Сейчас на сайте ',
        teaser_text: 'Выборка из нашего текущего предложения.',
        teaser_counter_1: 'Автомобили',
        teaser_counter_2: 'Пробег',
        teaser_counter_3: 'Месяц',
        how_title: 'Как это работает? ',
        how_text: 'Узнайте о ряде важных преимуществ, которые дает наш сайт.',
        how_1_title: 'Регистрация',
        how_1_text: 'Наши списки распродаж доступны только зарегистрированным профессиональным автодилерам. \n После проверки документов вашей компании ваши права доступа будут активированы.',
        how_2_title: 'Аукцион',
        how_2_text: 'Еженедельно мы представляем более 150 новых автомобилей, которые уходят покупателям, предложившим самую высокую цену. Ваши предложения не включают НДС. \n Вы предложили самую высокую цену? \n В таком случае автомобиль уходит к вам. \n Вы не несете никаких дополнительных аукционных расходов.',
        how_3_title: 'Списки с фиксированными ценами',
        how_3_text: 'Не повезло на аукционе? \n У вас еще есть шанс приобрести автомобиль из списка с фиксированными ценами. \n Просто нажмите «Купить» (Buy) и автомобиль ваш.',
        usp_title: 'Почему лучше покупать у нас? ',
        usp_text: 'Ниже приведены наши сильные стороны',
        usp_1_title: 'Полный ассортимент автомобилей производства Volkswagen Group',
        usp_1_text: 'Мы продаем только автомобили Volkswagen, Audi, SEAT, ŠKODA и Porsche.',
        usp_2_title: 'Полная история сервисного обслуживания',
        usp_2_text: 'Все автомобили обслуживаются в официальной дилерской сети.',
        usp_3_title: 'Большой выбор',
        usp_3_text: 'Автомобили с пробегом от 6 000 до 250 000 км, возрастом от 6 месяцев до 6 лет. \n Автомобили от класса А до класса люкс и служебных автомобилей',
        usp_4_title: 'Личный контакт',
        usp_4_text: 'Вам будет выделен сотрудник, который окажет вам помощь на каждом этапе покупки.',
        usp_5_title: 'Многоязычная поддержка',
        usp_5_text: 'Мы говорим на нидерландском, французском, английском и немецком языках. ',
        contact_title: 'Контактные данные',
        mobile_for: '',
        copyright: '© 2018 Все права защищены',
        conditions: 'Условия',
        privacy: 'Kонфиденциальность',
        today: 'сегодня',
        average: 'средний',
        GDPR_accept_btn: 'Accept cookies',
        GDPR_line_1: "This website uses cookies. We use cookies to make our website work at the best, improving your online experience.",
        GDPR_line_2: "By continuing to navigate on this site, you agree to the use of cookies.",
        GDPR_more: "Learn more"
    },
    ro: {
        code: 'ro',
        name: 'Română',
        home_title: 'Bun venit pe site-ul web de repunere pe piață a autovehiculelor de ocazie.',
        home_text: 'Ne vom întâlni regulat pe acest site pentru liste noi de vânzări.',
        login: 'CONECTARE',
        register: 'ÎNREGISTRARE',
        teaser_title: 'În prezent online ',
        teaser_text: 'O selecție din oferta noastră actuală.',
        teaser_counter_1: 'Automobile',
        teaser_counter_2: 'Chilometraje',
        teaser_counter_3: 'Lună',
        how_title: 'Cum funcţionează? ',
        how_text: 'Descoperiți o serie de avantaje importante ale soluției noastre.',
        how_1_title: 'Înregistrare',
        how_1_text: 'Listele noastre de vânzări sunt disponibile numai pentru dealeri profesionişti de automobile care sunt înregistrate. \n După verificarea documentelor firmei, vom activa drepturile dvs. de acces.',
        how_2_title: 'Licitaţie',
        how_2_text: 'În fiecare săptămână, prezentăm peste 150 de automobile noi care sunt vândute celui care oferă cel mai bun preț. Ofertele dvs. nu includ TVA. \n Sunteți cel care a oferit cel mai mare preț?  \n În acest caz, automobilul vă este atribuit. \n Nu plătiți nici un cost suplimentar pentru licitație.',
        how_3_title: 'Liste de prețuri fixe',
        how_3_text: 'N-ați avut noroc la licitație? \n Încă mai puteți profita de șansa dvs. la lista de vânzări care conține automobile la prețuri fixe. \n Dați pur și simplu clic pe „Cumpără” și automobilul este a dvs.',
        usp_title: 'De ce să cumpărați de la noi? ',
        usp_text: 'Mai jos aflați unde GĂSIȚI PREZENTARE GENERALĂ A PUNCTELOR FORTE',
        usp_1_title: 'Gama completă de la grupul Volkswagen',
        usp_1_text: 'Noi vindem doar automobile de marca: Volkswagen, Audi, SEAT, ŠKODA și Porsche.',
        usp_2_title: 'Un istoric de service complet de întreținere',
        usp_2_text: 'Service-ul tuturor mașinilor este executat în cadrul rețelei de dealeri oficiali.',
        usp_3_title: 'O ofertă variată',
        usp_3_text: 'Automobile cu chilometrajul de la 6.000 km la 250.000 km, de la 6 luni la 6 ani. \n De la categoria A, la categoria de lux și automobile de serviciu',
        usp_4_title: 'Contact personal',
        usp_4_text: 'Vi se va atribui o persoană de contact care vă va trece prin fiecare etapă a achiziției.',
        usp_5_title: 'Echipă de vânzări multilingvă',
        usp_5_text: 'Vorbim olandeză, franceză, engleză și germană. ',
        contact_title: 'Informații de contact',
        mobile_for: '',
        copyright: '© 2018 Toate drepturile rezervate',
        conditions: 'Termeni și Condiții ',
        privacy: 'Confidențialitate',
        today: 'astăzi',
        average: 'in medie',
        GDPR_accept_btn: 'Accept cookies',
        GDPR_line_1: "This website uses cookies. We use cookies to make our website work at the best, improving your online experience.",
        GDPR_line_2: "By continuing to navigate on this site, you agree to the use of cookies.",
        GDPR_more: "Learn more"
    },
    pt: {
        code: 'pt',
        name: 'Português',
        home_title: 'Bem-vindo ao Web site da Used Cars Remarketing.',
        home_text: 'Iremos encontrar-nos regularmente neste Web site com novas listas de vendas.',
        login: 'INICIAR SESSÃO',
        register: 'REGISTAR',
        teaser_title: 'Atualmente online ',
        teaser_text: 'Uma seleção da nossa oferta atual.',
        teaser_counter_1: 'Automóveis',
        teaser_counter_2: 'Quilometragem',
        teaser_counter_3: 'Mês',
        how_title: 'Como funciona?',
        how_text: 'Descubra os diversos benefícios importantes das nossas soluções.',
        how_1_title: 'Registar',
        how_1_text: 'As nossas listas de vendas estão disponíveis apenas para comerciantes de automóveis profissionais registados. \n Depois de verificar os seus documentos societários, iremos ativar os seus direitos de acesso.',
        how_2_title: 'Leilão',
        how_2_text: 'Todas as semanas, apresentamos mais de 150 automóveis novos que são vendidos ao proponente que apresente a melhor oferta. As suas licitações não incluem IVA. \n É o autor da melhor oferta? \n Nesse caso, o automóvel é-lhe atribuído. \n Não paga qualquer valor adicional pelos custos do leilão.',
        how_3_title: 'Listas de preços fixos',
        how_3_text: 'Não teve sorte no leilão? \n Ainda pode aproveitar a oportunidade na lista de vendas que contém automóveis a preços fixos. \n Basta clicar em "Comprar" e o automóvel é seu.',
        usp_title: 'Quem são os nossos compradores? ',
        usp_text: 'Abaixo encontra uma descrição geral dos nossos pontos fortes',
        usp_1_title: 'A gama completa do Grupo Volkswagen',
        usp_1_text: 'Vendemos apenas automóveis Volkswagen, Audi, SEAT, ŠKODA e Porsche.',
        usp_2_title: 'Histórico de manutenção completo',
        usp_2_text: 'A manutenção de todos os automóveis é realizada na rede de concessionários oficiais.',
        usp_3_title: 'Uma oferta variada',
        usp_3_text: 'Automóveis com uma quilometragem de 6 000 km a 250 000 km, de 6 meses a 6 anos. \n Da categoria A ao segmento de luxo, sem esquecer os veículos comerciais',
        usp_4_title: 'Contacto pessoal',
        usp_4_text: 'Ser-lhe-á atribuída uma pessoa de contacto que o irá orientar em todos os passos da sua compra.',
        usp_5_title: 'Equipa de vendas multilingue',
        usp_5_text: 'Falamos neerlandês, francês, inglês e alemão. ',
        contact_title: 'Informações de contacto',
        mobile_for: '',
        copyright: '© 2018 Todos os direitos reservados',
        conditions: 'Termos e condições',
        privacy: 'Privacidade',
        today: 'hoje',
        average: 'média',
        GDPR_accept_btn: 'Accept cookies',
        GDPR_line_1: "This website uses cookies. We use cookies to make our website work at the best, improving your online experience.",
        GDPR_line_2: "By continuing to navigate on this site, you agree to the use of cookies.",
        GDPR_more: "Learn more"
    },
    pl: {
        code: 'pl',
        name: 'Polski',
        home_title: 'Witamy na stronie Used Cars Remarketing.',
        home_text: 'Regularnie gromadzimy zapisy na nowe listy sprzedażowe na naszej stronie internetowej.',
        login: 'ZALOGUJ SIĘ',
        register: 'ZAREJESTRUJ SIĘ',
        teaser_title: 'Obecnie online ',
        teaser_text: 'Niektóre z naszych aktualnych ofert.',
        teaser_counter_1: 'Pojazdy',
        teaser_counter_2: 'Przebieg',
        teaser_counter_3: 'Miesiąc',
        how_title: 'Jak to działa? ',
        how_text: 'Odkryj najważniejsze zalety naszego rozwiązania.',
        how_1_title: 'Zarejestruj się',
        how_1_text: 'Nasze listy sprzedażowe dostępne są wyłącznie dla zarejestrowanych handlarzy samochodowych. \n Dostęp zostaje aktywowany po weryfikacji dokumentów firmowych.',
        how_2_title: 'Aukcja',
        how_2_text: 'Co tydzień publikujemy ponad 150 nowych pojazdów, które zostają sprzedane klientowi, który przedstawi najwyższą ofertę. Oferty nie zawierają podatku VAT. \n Oferujesz najwyższą cenę? \n Pojazd trafia do Ciebie. \n Nie ponosisz żadnych dodatkowych kosztów aukcyjnych.',
        how_3_title: 'Stałe cenniki',
        how_3_text: 'Nie powiodło Ci się na aukcji? \n Zawsze możesz spróbować z listą sprzedażową zawierającą pojazdy w stałej cenie. \n Wystarczy, że klikniesz KUP, a pojazd jest Twój.',
        usp_title: 'Dlaczego warto kupować u nas? ',
        usp_text: 'Poniżej lista naszych mocnych stron.',
        usp_1_title: 'Pełna gama modeli Volkswagena',
        usp_1_text: 'Sprzedajemy wyłącznie modele Volkswagena, Audi, SEATA, ŠKODY i Porsche.',
        usp_2_title: 'Pełna historia przeglądów',
        usp_2_text: 'Wszystkie pojazdy konserwowane są w oficjalnej sieci dealerów.',
        usp_3_title: 'Zróżnicowana oferta',
        usp_3_text: 'Pojazdy o przebiegu od 6 000 km do 250 000 km w wieku od 6 miesięcy do 6 lat. \n Od klasy A po segment luksusowy i pojazdy dostawcze.',
        usp_4_title: 'Osobisty kontakt',
        usp_4_text: 'Wyznaczymy Ci przedstawiciela, który wspomoże Cię na każdym etapie zakupu.',
        usp_5_title: 'Wielojęzyczny zespół ds. sprzedaży',
        usp_5_text: 'Mówimy po niderlandzku, francusku, angielsku i niemiecku. ',
        contact_title: 'Informacje kontaktowe',
        mobile_for: '',
        copyright: '© 2018 Wszystkie prawa zastrzeżone. ',
        conditions: 'Warunki ogólne ',
        privacy: 'Polityka prywatności',
        today: 'dzisiaj',
        average: 'średni',
        GDPR_accept_btn: 'Accept cookies',
        GDPR_line_1: "This website uses cookies. We use cookies to make our website work at the best, improving your online experience.",
        GDPR_line_2: "By continuing to navigate on this site, you agree to the use of cookies.",
        GDPR_more: "Learn more"
    },
    no: {
        code: 'no',
        name: 'Norsk',
        home_title: 'Velkommen til Used Cars Remarketing-nettsiden.',
        home_text: 'Vi inngår avtaler med dere om nye salgslister på denne nettsiden med jevne mellomrom.',
        login: 'LOGG INN',
        register: 'REGISTRER',
        teaser_title: 'Nå online ',
        teaser_text: 'Et utvalg av vårt aktuelle tilbud.',
        teaser_counter_1: 'Biler',
        teaser_counter_2: 'Kilometerstand',
        teaser_counter_3: 'Måned',
        how_title: 'Hvordan fungerer det? ',
        how_text: 'Oppdag en rekke fordeler med vår løsning.',
        how_1_title: 'Registrer',
        how_1_text: 'Salgslistene våre er kun tilgjengelige for registrerte profesjonelle bilselgere. \n Vi aktiverer din tilgang etter å ha kontrollert bedriftspapirene dine.',
        how_2_title: 'Auksjon',
        how_2_text: 'Hver uke publiserer vi mer enn 150 nye biler som selges til høystbydende. Dine bud er ekskl. MVA. \n Er du høystbydende? \n Da tilkjennes bilen deg. \n Du betaler ingen ekstra auksjonskostnader.',
        how_3_title: 'Faste prislister',
        how_3_text: 'Lyktes det ikke via auksjonen? \n Da er det likevel mulig å benytte seg av salgslisten med biler til fastpris. \n Du klikker bare på «Kjøp», så er bilen din. ',
        usp_title: 'Hvorfor kjøpe hos oss? ',
        usp_text: 'Nedenfor finner du en oversikt over produktkategoriene våre.',
        usp_1_title: 'Hele Volkswagen-gruppens produktutvalg',
        usp_1_text: 'Vi selger kun Volkswagen, Audi, SEAT, ŠKODA og Porsche.',
        usp_2_title: 'Fullstendig vedlikeholdshistorikk',
        usp_2_text: 'Alle bilene er vedlikeholdt i det offisielle forhandlernettverket.',
        usp_3_title: 'Variert tilbud',
        usp_3_text: 'Biler fra 6 000 km til 250 000 km fra 6 måneder til 6 år. \n Fra A-segment til luksussegmentet og firmabiler. ',
        usp_4_title: 'Personlig kontakt for deg',
        usp_4_text: 'Du blir tildelt en kontaktperson som kommer til å bistå deg i hvert steg av kjøpsprosessen.',
        usp_5_title: 'Flerspråklig salgsteam',
        usp_5_text: 'Vi snakker nederlandsk, fransk, engelsk og tysk. ',
        contact_title: 'Kontaktinformasjon',
        mobile_for: '',
        copyright: '© 2018 Alle rettigheter forbeholdt',
        conditions: 'Generelle betingelser',
        privacy: 'Personvern',
        today: 'i dag',
        average: 'gjennomsnitt',
        GDPR_accept_btn: 'Accept cookies',
        GDPR_line_1: "This website uses cookies. We use cookies to make our website work at the best, improving your online experience.",
        GDPR_line_2: "By continuing to navigate on this site, you agree to the use of cookies.",
        GDPR_more: "Learn more"
    },
    lv: {
        code: 'lv',
        name: 'Latviešu',
        home_title: 'Laipni lūgti lietoto automašīnu tālākpārdošanas tīmekļa vietnē.',
        home_text: 'Mēs regulāri tiksimies šajā tīmekļa vietnē, lai apskatītu jaunus pārdošanas piedāvājumus.',
        login: 'AUTORIZĒTIES',
        register: 'REĢISTRĀCIJA',
        teaser_title: 'Pašlaik tiešsaistē ',
        teaser_text: 'Izlase no mūsu pašreizējā piedāvājuma.',
        teaser_counter_1: 'Automašīnas',
        teaser_counter_2: 'Nobraukums',
        teaser_counter_3: 'Mēnesis',
        how_title: 'Kā tas notiek? ',
        how_text: 'Atklājiet vairākus svarīgus ieguvumus no mūsu risinājuma.',
        how_1_title: 'Reģistrācija',
        how_1_text: 'Mūsu tirdzniecības saraksti ir pieejami tikai reģistrētiem profesionāliem automašīnu izplatītājiem. \n Pēc Jūsu uzņēmuma dokumentu pārbaudes mēs aktivizēsim Jūsu piekļuves tiesības.',
        how_2_title: 'Izsole',
        how_2_text: 'Katru nedēļu mēs piedāvājam vairāk nekā 150 jaunas automašīnas, kas tiek pārdotas augstākās cenas solītājam. Jūsu piedāvājumos nav iekļauts PVN. \n Vai esat augstākās cenas solītājs? \n Tādā gadījumā automašīna tiek piešķirta Jums. \n Jums nav jāmaksā nekādas papildu izsoles izmaksas.',
        how_3_title: 'Fiksētas cenas saraksti',
        how_3_text: 'Neveicas izsolēs? \n Joprojām varat izmantot savu iespēju pārdošanas sarakstos, kuros ir automašīnas par fiksētām cenām. \n Vienkārši klikšķiniet “Pirkt”, un automašīna ir Jūsu.',
        usp_title: 'Kādēļ iegādāties no mums? ',
        usp_text: 'Turpmāk izklāstītas mūsu stiprās puses.',
        usp_1_title: 'Pilns Volkswagen grupas automašīnu klāsts',
        usp_1_text: 'Mēs pārdodam tikai Volkswagen, Audi, SEAT, ŠKODA un Porsche automašīnas.',
        usp_2_title: 'Pilna servisa vēsture',
        usp_2_text: 'Visas automašīnas ir apkalpotas oficiālo izplatītāju tīkla servisos.',
        usp_3_title: 'Daudzveidīgs piedāvājums',
        usp_3_text: 'Automašīnas ar nobraukumu robežās no 6 000 km līdz 250 000 km, no sešiem mēnešiem līdz sešiem gadiem. \n No A kategorijas līdz luksusa kategorijai un korporatīvajām automašīnām.',
        usp_4_title: 'Personīgs kontakts',
        usp_4_text: 'Jums tiks piešķirta sava kontaktpersona, kas Jums būs pieejama visa iegādes procesa laikā.',
        usp_5_title: 'Daudzvalodīga pārdošanas komanda',
        usp_5_text: 'Mēs runājam holandiešu, franču, angļu un vācu valodās. ',
        contact_title: 'Kontaktinformācija',
        mobile_for: '',
        copyright: '© 2018 Visas tiesības aizsargātas. ',
        conditions: 'Noteikumi ',
        privacy: 'Konfidencialitāte',
        today: 'šodien',
        average: 'vidējais',
        GDPR_accept_btn: 'Accept cookies',
        GDPR_line_1: "This website uses cookies. We use cookies to make our website work at the best, improving your online experience.",
        GDPR_line_2: "By continuing to navigate on this site, you agree to the use of cookies.",
        GDPR_more: "Learn more"

    },
    lt: {
        code: 'lt',
        name: 'Lietuvių kalba',
        home_title: 'Sveiki apsilankę naudotų automobilių pardavimo svetainėje.',
        home_text: 'Joje dažnai susitiksime peržiūrėti naujų pardavimo sąrašų.',
        login: 'PRISIJUNGTI',
        register: 'UŽSIREGISTRUOKITE',
        teaser_title: 'Šiuo metu parduodama internetu ',
        teaser_text: 'Atrinkta iš mūsų dabartinių pasiūlymo.',
        teaser_counter_1: 'Automobiliai',
        teaser_counter_2: 'Rida',
        teaser_counter_3: 'Mėnuo',
        how_title: 'Kaip tai veikia',
        how_text: 'Sužinokite įvairią svarbią mūsų sprendimo teikiamą naudą.',
        how_1_title: 'Užsiregistruokite',
        how_1_text: 'Mūsų pardavimo sąrašai prieinami tik užsiregistravusiems profesionaliems automobilių prekiautojams. \n Patikrinę įmonės dokumentus, aktyvuosime jūsų prieigos teisę.',
        how_2_title: 'Aukcionas',
        how_2_text: 'Kas savaitę pristatome virš 150 naujų automobilių, kurie parduodami didžiausią kainą pasiūliusiam pirkėjui. Jūsų siūlomos kainos yra be PVM. \n Esate didžiausią kainą pasiūlęs pirkėjas? \n Tokiu atveju automobilis atitenka jums. \n Nemokate jokių papildomų aukciono mokesčių.',
        how_3_title: 'Fiksuotos kainos sąrašai',
        how_3_text: 'Nepasisekė aukcione? \n Dar galite pasinaudoti galimybėmis, kurias rasite fiksuotos kainos automobilių sąrašuose. \n Tiesiog spūstelėkite „Pirkti“ ir automobilis jūsų.',
        usp_title: 'Kodėl pirkti iš mūsų? ',
        usp_text: 'Žemiau apžvelgiamos mūsų stiprybės',
        usp_1_title: 'Visas „Volkswagen Group“ asortimentas',
        usp_1_text: 'Prekiaujame tik „Volkswagen“, „Audi“, „SEAT“, „ŠKODA“ ir „Porsche“ automobiliais.',
        usp_2_title: 'Visa aptarnavimo istorija',
        usp_2_text: 'Visus automobiliai aptarnauja oficialių tarpininkų tinklas.',
        usp_3_title: 'Įvairialypė pasiūla',
        usp_3_text: '6 000-250 000 km ridos, nuo 6 mėnesių iki 6 metų senumo automobiliai. \n Nuo A klasės iki prabangių ir įmonių naudojamų automobilių',
        usp_4_title: 'Asmeninis kontaktas',
        usp_4_text: 'Jums bus paskirtas kontaktinis asmuo, kuris jums padės atlikti visus pirkimo žingsnius.',
        usp_5_title: 'Daugiakalbė pardavimo komanda',
        usp_5_text: 'Kalbame olandų, prancūzų, anglų ir vokiečių kalbomis. ',
        contact_title: 'Kontaktiniai duomenys',
        mobile_for: '',
        copyright: '© 2018. Visos teisės saugomos',
        conditions: 'Sąlygos ir nuostatos',
        privacy: 'Privatumas',
        today: 'šiandien',
        average: 'vidurkis',
        GDPR_accept_btn: 'Accept cookies',
        GDPR_line_1: "This website uses cookies. We use cookies to make our website work at the best, improving your online experience.",
        GDPR_line_2: "By continuing to navigate on this site, you agree to the use of cookies.",
        GDPR_more: "Learn more"
    },
    it: {
        code: 'it',
        name: 'Italiano',
        home_title: 'Vi diamo il benvenuto nel sito web di Used Cars Remarketing.',
        home_text: 'Terremo regolarmente aggiornato questo sito web con nuovi listini di vendita.',
        login: 'ACCEDI',
        register: 'Registrazione',
        teaser_title: 'Attualmente online ',
        teaser_text: 'Una selezione della nostra attuale offerta.',
        teaser_counter_1: 'Automobili',
        teaser_counter_2: 'Chilometraggio',
        teaser_counter_3: 'Mese',
        how_title: 'Come funziona?',
        how_text: 'Scoprite alcuni degli importanti vantaggi della nostra soluzione.',
        how_1_title: 'Registrazione',
        how_1_text: 'I nostri listini di vendita sono disponibili solo per i concessionari professionali registrati. \n Dopo avere controllato la vostra documentazione aziendale, attiveremo la vostra autorizzazione d’accesso.',
        how_2_title: 'Asta',
        how_2_text: 'Ogni settimana presentiamo oltre 150 nuove automobili che vengono vendute al miglior offerente. Le offerte si intendono IVA esclusa. \n Siete il miglior offerente? \n In questo caso, l’automobile sarà assegnata a voi. \n Non pagherete alcun costo aggiuntivo per l’asta.',
        how_3_title: 'Listini prezzi fissi',
        how_3_text: 'Non avete avuto fortuna con l’asta? \n Potete ancora tentare la sorte con i listini di vendita delle automobili a prezzo fisso. \n Sarà sufficiente fare clic su “Acquista” e l’automobile sarà vostra.',
        usp_title: 'Perché acquistare da noi? ',
        usp_text: 'Qui di seguito una panoramica dei nostri punti di forza',
        usp_1_title: 'La gamma completa del gruppo Volkswagen',
        usp_1_text: 'Vendiamo solo veicoli dei marchi Volkswagen, Audi, SEAT, ŠKODA e Porsche.',
        usp_2_title: 'Storico completo delle manutenzioni',
        usp_2_text: 'Le manutenzioni di tutte le automobili avvengono all’interno del network di concessionari ufficiali.',
        usp_3_title: 'Offerta variegata',
        usp_3_text: 'Automobili con un chilometraggio che va dai 6.000 ai 250.000 km, dai 6 mesi ai 6 anni. \n Dal segmento A, a quello di lusso, fino ai veicoli aziendali.',
        usp_4_title: 'Contatto personale',
        usp_4_text: 'Verrete assegnati a una persona di contatto che vi guiderà in ogni fase dell’acquisto.',
        usp_5_title: 'Team vendite multilingue',
        usp_5_text: 'Parliamo olandese, francese, inglese e tedesco. ',
        contact_title: 'Informazioni di contatto',
        mobile_for: '',
        copyright: '© 2018 Tutti i diritti riservati.',
        conditions: 'Termini e condizioni ',
        privacy: 'Informativa sulla privacy',
        today: 'oggi',
        average: 'media',
        GDPR_accept_btn: 'Accept cookies',
        GDPR_line_1: "This website uses cookies. We use cookies to make our website work at the best, improving your online experience.",
        GDPR_line_2: "By continuing to navigate on this site, you agree to the use of cookies.",
        GDPR_more: "Learn more"

    },
    hu: {
        code: 'hu',
        name: 'Magyar',
        home_title: 'Üdvözöljük a használt autók újraértékesítésével foglalkozó honlapon.',
        home_text: 'Rendszeresen fogunk találkozni ezen a honlapon a friss értékesítési listák közzétételekor.',
        login: 'BEJELENTKEZÉS',
        register: 'REGISZTRÁCIÓ',
        teaser_title: 'Jelenleg online ',
        teaser_text: 'Válogatás a jelenlegi kínálatunkból.',
        teaser_counter_1: 'Autók',
        teaser_counter_2: 'Kilométeróra állása',
        teaser_counter_3: 'Hónap',
        how_title: 'Hogyan működik?',
        how_text: 'Fedezze fel megoldásunk számos fontos előnyét.',
        how_1_title: 'Regisztráció',
        how_1_text: 'Értékesítési listáink csak a regisztrált, hivatalos autókereskedők számára érhetők el. \n Céges dokumentumai ellenőrzése után aktiváljuk a hozzáférési jogosultságokat.',
        how_2_title: 'Aukció',
        how_2_text: 'Minden héten 150 új autót mutatunk be, amelyeket a legnagyobb összeget ajánló ajánlattevőnek adunk el. Az Ön által megtett ajánlatok nem tartalmaznak áfát. \n Ön tette a legnagyobb ajánlatot? \n Ebben az esetben az autót hozzárendeljük Önhöz. \n Nem kell fizetnie extra aukciós költséget.',
        how_3_title: 'Rögzített árlisták',
        how_3_text: 'Nem volt szerencséje az aukción? \n Még mindig élhet azzal a lehetőséggel, hogy a rögzített árú autókat tartalmazó értékesítési listáról választ. \n Egyszerűen kattintson a „Vásárlás” lehetőségre, és az autó az Öné.',
        usp_title: 'Miért érdemes tőlünk vásárolni? ',
        usp_text: 'Alább áttekintést kaphat erősségeinkről',
        usp_1_title: 'A Volkswagen csoport teljes termékcsaládja',
        usp_1_text: 'Kizárólag Volkswagent, Audit, SEATOT, ŠKODÁT és Porschét árulunk.',
        usp_2_title: 'Teljes szerviztörténet',
        usp_2_text: 'Valamennyi autót a hivatalos viszonteladóink hálózatában szervizelünk.',
        usp_3_title: 'Változatos ajánlatok',
        usp_3_text: '6 000–250 000 km-t futott autók, 6 hónapostól a 6 évesig. \n A-kategóriától a luxuskategóriáig és a céges autókig.',
        usp_4_title: 'Személyes kapcsolatfelvétel',
        usp_4_text: 'Kap egy személyes kapcsolattartót, aki végigvezeti a vásárlás minden lépésén.',
        usp_5_title: 'Többnyelvű értékesítési csapat',
        usp_5_text: 'Beszélünk hollandul, franciául, angolul és németül. ',
        contact_title: 'Kapcsolattartási adatok',
        mobile_for: '',
        copyright: '© 2018 Minden jog fenntartva',
        conditions: 'Általános szerződési feltételek ',
        privacy: 'Adatvédelmi nyilatkozat',
        today: 'Ma',
        average: 'átlagos',
        GDPR_accept_btn: 'Accept cookies',
        GDPR_line_1: "This website uses cookies. We use cookies to make our website work at the best, improving your online experience.",
        GDPR_line_2: "By continuing to navigate on this site, you agree to the use of cookies.",
        GDPR_more: "Learn more"
    }


    //code: '',
// name: '',
    // home_title: '',
    // home_text: '',
    // login: '',
    // register: '',
    // teaser_title: '',
    // teaser_text: '',
    // teaser_counter_1: '',
    // teaser_counter_2: '',
    // teaser_counter_3: '',
    // how_title: ' ',
    // how_text: '',
    // how_1_title: '',
    // how_1_text: '\n',
    // how_2_title: '',
    // how_2_text: '\n \n \n',
    // how_3_title: '',
    //   how_3_text: '\n \n',
    // usp_title: '',
    // usp_text: '',
    // usp_1_title: '',
    // usp_1_text: '',
    // usp_2_title: '',
    // usp_2_text: '',
    // usp_3_title: '',
    // usp_3_text: '\n',
    // usp_4_title: '',
    // usp_4_text: '',
    // usp_5_title: '',
    // usp_5_text: '',
    // contact_title: '',
    // mobile_for: '',
    // copyright: '',
    // conditions: '',
    // privacy: ''
    // GDPR_accept_btn: '',
    // GDPR_line_1: "",
    // GDPR_line_2: "",
    // GDPR_more: ""

};